@import "colors";

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "secondary-1": $secondary-1,
  "secondary-2": $secondary-2,
  "secondary-3": $secondary-3,
  "secondary-4": $secondary-4,
  "secondary-5": $secondary-5,
  "secondary-6": $secondary-6,
  "secondary-7": $secondary-7,
  "secondary-8": $secondary-8,
  "secondary-9": $secondary-9,
  "variation-1": $variation-1,
  "variation-2": $variation-2,
  "variation-3": $variation-3,
  "variation-4": $variation-4,
  "variation-5": $variation-5,
  "variation-6": $variation-6,
  "variation-7": $variation-7,
  "variation-8": $variation-8,
  "variation-9": $variation-9,
  "variation-10": $variation-10,
);

$body-color: $primary;
$body-bg: $secondary-8;

$font-family-sans-serif: 'Roboto', sans-serif;

$headings-font-family: 'Archia', sans-serif;

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.625;
$h2-font-size: $font-size-base * 2.5;
$h3-font-size: $font-size-base * 2.25;
$h4-font-size: $font-size-base * 1.375;
$h5-font-size: $font-size-base * 1.125;
$h6-font-size: $font-size-base * 1;

$btn-font-family: 'Archia', sans-serif;
$btn-font-weight: 700;
$btn-border-radius: 0.5rem;
$input-btn-padding-y: 0.875rem;
$input-btn-padding-x: 1.125rem;



$spacer: 1rem; //16px
$spacers: (
  0: 0, //0px
  1: $spacer * .25, //4px
  2: $spacer * .5, //8px
  3: $spacer, //16px
  4: $spacer * 1.5, //24px
  5: $spacer * 2.1875, //35px
  6: $spacer * 2.5, //40px
  7: $spacer * 5, //0px
  8: $spacer * 6.25, //100px
  9: $spacer * 8.25, //132px
);

$breadcrumb-divider-color: $secondary;
$breadcrumb-active-color: $secondary;

@import "~bootstrap/scss/bootstrap";

.btn.btn-link {
  padding:0!important;
}

h1,.h1,h2,.h2,h3,.h3,h4,.h4 {
  font-weight: 700;
}

h5,.h5, h6, .h6 {
  font-family: 'Roboto', sans-serif!important;
  font-weight: 400;
}

.ff-roboto {
  font-family: 'Roboto', sans-serif!important;
}

.ff-archia {
  font-family: 'Archia', sans-serif!important;
}

.header-nav {
  border-radius: 4px;
  padding: 6px 12px;
}

.header-nav:hover {
  background-color: $secondary-5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
}
.header-nav:active, .header-nav.active {
  background-color: $secondary-5;
}

.shadow {
  box-shadow: 0 0 10px 0 #C7C7F1!important;
  filter: drop-shadow(0px 0px 10px #C7C7F1)!important;
}
