@import "~swiper/swiper.min.css";
@import "~swiper/swiper-bundle.min.css";

.swiper-slide {
  height: auto;
}

.swiper-button-prev,.swiper-button-next {
  background-color: white;
  border-radius: 100%;
  color:black;
  width:2rem;
  height:2rem;
  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 16%);
  transition: transform 0.2s ease-in-out;
}

.swiper-button-prev:hover,.swiper-button-next:hover {
  transform: scale(120%,120%);
}

.swiper-button-prev:after,.swiper-button-next:after {
  font-size: 1rem;
}

@media (min-width: 1200px) {
  swiper.swiper.special {
    padding: 30px;
    margin: -30px;
  }
}
