$primary: #01005b;
$secondary: #fbb91c;
$success: #20A39E;
$info: #446DF6;
$warning: #FB5012;
$danger: #FF3C38;
$light: #fcfcfc;
$dark: #020225;
$secondary-1: #020225;
$secondary-2: #5b5b95;
$secondary-3: #2f42b7;
$secondary-4: #99adff;
$secondary-5: #d0d9ff;
$secondary-6: #ff8277;
$secondary-7: #54dbaa;
$secondary-8: #f4f4fb;
$secondary-9: #fcfcfc;
$variation-1: #242472;
$variation-2: #424187;
$variation-3: #aab7ed;
$variation-4: #c1cdff;
$variation-5: #65ec92;
$variation-6: #93f7b4;
$variation-7: #e29f00;
$variation-8: #ffcf5c;
$variation-9: #ffe29e;
$variation-10: #6ad18d;
