$headerHeight: 124px;

header {
  height:$headerHeight;
}

.main {
  margin-top: $headerHeight;
  min-height: calc(100vh - #{$headerHeight});
}

.full-height {
  min-height: calc(100vh - #{$headerHeight});
}

.mobile-menu {
  top: $headerHeight;
  position: fixed;
  z-index: 1000;
}

.bg-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.panel {
  min-width: 300px;
  max-width: 500px;
}

.w-33 {
  width: calc(100% / 3);
}

.btn-width {
  min-width: 200px;
}

.no-cursor {
  cursor: default;
}

.white-panel {
  width: 300px;
}


// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .w-lg-50 {
    width: 50%;
  }

}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  $headerHeight: 124px;

  .main {
    margin-top: $headerHeight;
    min-height: calc(100vh - #{$headerHeight});
  }

  .full-height {
    min-height: calc(100vh - #{$headerHeight});
  }


  .white-panel {
    width: 360px;
  }
}
