.fw-semi-bold {
  font-weight: 600;
}

.fw-medium {
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

i.for-dialog {
  font-size: 1.5rem;
}
