@font-face {
  font-family: "Archia";
  src: local("Archia"), url("../src/assets/fonts/Archia-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Archia";
  src: local("Archia"), url("../src/assets/fonts/Archia-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Archia";
  src: local("Archia"), url("../src/assets/fonts/Archia-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Archia";
  src: local("Archia"), url("../src/assets/fonts/Archia-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@import "assets/scss/bootstrap";
@import "assets/scss/material";
@import "assets/scss/swiper";
@import "assets/scss/typo";
@import "assets/scss/layout";
@import "assets/scss/fontawesome";
