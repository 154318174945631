@use '@angular/material' as mat;
@include mat.core();

$pal-primary: (
  50 : #e1e0eb,
  100 : #b3b3ce,
  200 : #8080ad,
  300 : #4d4d8c,
  400 : #272674,
  500 : #01005b,
  600 : #010053,
  700 : #010049,
  800 : #010040,
  900 : #00002f,
  A100 : #6868ff,
  A200 : #3535ff,
  A400 : #0202ff,
  A700 : #0000e7,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$pal-secondary: (
  50 : #fff7e4,
  100 : #feeabb,
  200 : #fddc8e,
  300 : #fcce60,
  400 : #fcc43e,
  500 : #fbb91c,
  600 : #fab219,
  700 : #faaa14,
  800 : #f9a211,
  900 : #f89309,
  A100 : #ffffff,
  A200 : #fff7ee,
  A400 : #ffdfbb,
  A700 : #ffd4a1,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$pal-danger: (
  50 : #ffe8e7,
  100 : #ffc5c3,
  200 : #ff9e9c,
  300 : #ff7774,
  400 : #ff5956,
  500 : #ff3c38,
  600 : #ff3632,
  700 : #ff2e2b,
  800 : #ff2724,
  900 : #ff1a17,
  A100 : #ffffff,
  A200 : #fffcfc,
  A400 : #ffcac9,
  A700 : #ffb1b0,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);



$client-primary: mat.define-palette($pal-primary);
$client-accent: mat.define-palette($pal-secondary, A200, A100, A400);
$client-warn: mat.define-palette(mat.$red-palette);
$client-theme: mat.define-light-theme((
  color: (
    primary: $client-primary,
    accent: $client-accent,
    warn: $client-warn,
  )
));
@include mat.all-component-themes($client-theme);

@mixin mix-app-theme($client-theme) {
  $primary: map-get($client-theme, primary);

  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($pal-primary, 500);
    color: white!important;
  }
}

// Include the mixin
@include mix-app-theme($client-theme);

mat-form-field.no-pb .mat-form-field-wrapper {
padding-bottom: 0;
}

mat-form-field.no-pb .mat-form-field-underline {
  bottom: 0;
}
